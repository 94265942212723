import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout"

import styles from "./styles.module.css"

export default function Template({
    data, // this prop will be injected by the GraphQL query below.
}) {
    const { markdownRemark } = data // data.markdownRemark holds your post data
    const { frontmatter, html } = markdownRemark
    return (
        <Layout>
            <div className={['wrapper', styles.recipeDetails].join(' ')}>
                    <div className={styles.heading}>
                        <h1>{frontmatter.title}</h1>
                        <img className={styles.cover} src={frontmatter.cover}/>
                        <div className={styles.cta}>
                            <p>Read the full method with a free Vegly account.</p>
                            <a className="btn" href="//app.eatvegly.com/signup">Create a free Vegly account</a>
                        </div>
                    </div>
                    <div className={styles.contentBody}>
                        <div
                            dangerouslySetInnerHTML={{ __html: html }}
                        />
                        <div className={styles.cta}>
                            <p>Build your meal plan and browse many more recipes with a free Vegly account.</p>
                            <a className="btn" href="//app.eatvegly.com/signup">Create a free Vegly account</a>
                        </div>
                    </div>
            </div>
        </Layout>
    )
}
export const pageQuery = graphql`
    query($slug: String!) {
        markdownRemark(fields: { slug: { eq: $slug } }) {
            html
            frontmatter {
                title
                cover
            }
        }
    }
`